var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.searchInMessages ? _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "left": ""
          },
          on: {
            "click": _vm.navigateToDashboard
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" Uleste meldinger ")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "color": "white",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.isFilterOpen = !_vm.isFilterOpen;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-filter-variant")]), _vm._v(" " + _vm._s(_vm.isFilterOpen ? "Skjul filter" : "Vis filter") + " ")], 1)];
      },
      proxy: true
    }], null, false, 1108029283)
  }, [_vm.isFilterOpen ? _c('v-row', {
    staticClass: "px-4"
  }, [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.avaliableSubjects,
      "label": "Emne",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.subject,
      callback: function callback($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.avaliableRecipients,
      "label": "Til",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.to,
      callback: function callback($$v) {
        _vm.to = $$v;
      },
      expression: "to"
    }
  })], 1), _c('v-col', [_c('v-autocomplete', {
    attrs: {
      "items": _vm.avaliableSenders,
      "label": "Fra",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.from,
      callback: function callback($$v) {
        _vm.from = $$v;
      },
      expression: "from"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "clearable": "",
      "hide-details": "",
      "label": "Innhold"
    },
    model: {
      value: _vm.content,
      callback: function callback($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)], 1) : _vm._e(), _c('CourseMessageList', {
    attrs: {
      "messages": _vm.searchInMessages,
      "onDashboard": true
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }