
import { dashboardApi } from "@/api/api";
import { ApiCourseResponsibleFilter, ApiGetMessageResultDto } from "@/api/generated/Api";
import CourseMessageList from "@/components/course/details/messages/CourseMessageList.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import { CourseMessageBoxType } from "@/shared/enums/CourseMessageBoxType.enum";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { uniqueArray } from "@/shared/helpers/arrayHelpers";
import { getDashboardUserId } from "@/shared/helpers/dashboardHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { mapMessageResponse } from "@/shared/helpers/messageHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { Ref, computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "UnreadCourseMessagesPage",
  components: { CourseMessageList, BaseLayout },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentEmployeeId = ref<number>();
    const messagesInboxUnread: Ref<ApiGetMessageResultDto[]> = ref([]);

    const isFilterOpen = ref(false);
    const validateQueryString = (value: string | (string | null)[]) => (typeof value === "string" ? value : undefined);

    const subject = ref(validateQueryString(route.query.subject));
    const from = ref(validateQueryString(route.query.sender));
    const to = ref(validateQueryString(route.query.to));
    const content = ref(validateQueryString(route.query.from));

    const fetchUnreadMessages = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = (
          await dashboardApi.messaging.searchMessagesKursadmin({
            CourseResponsibleFilter: ApiCourseResponsibleFilter.SUPERVISOR_AND_LECTURER,
            UserId: currentEmployeeId.value,
            IsRead: false,
            Sender: false,
          })
        ).data.items;

        if (response) {
          messagesInboxUnread.value = response.map((current) => mapMessageResponse(current));
        }
      });
    };

    const searchInMessages = computed(() => {
      router.replace({
        query: {
          subject: subject.value,
          to: to.value,
          from: from.value,
          content: content.value,
        },
      });
      return messagesInboxUnread.value
        .filter((currentMessage) => (subject.value ? currentMessage.subject?.includes(subject.value) : true))
        .filter((currentMessage) => (from.value ? currentMessage.sender?.includes(from.value) : true))
        .filter((currentMessage) => (to.value ? currentMessage.recipients?.join(", ")?.includes(to.value) : true))
        .filter((currentMessage) =>
          content.value ? currentMessage.text?.toLowerCase()?.includes(content.value.toLowerCase()) : true
        );
    });

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        currentEmployeeId.value = await getDashboardUserId();
        await fetchUnreadMessages();
      });
    });

    return {
      to,
      from,
      subject,
      content,
      searchInMessages,
      CourseMessageBoxType,
      isFilterOpen,
      navigateToDashboard: () => useNavigateBack(DashboardRouteNames.Home),
      avaliableSubjects: computed(() =>
        uniqueArray(searchInMessages.value.map((currentMessage) => currentMessage.subject))
      ),
      avaliableSenders: computed(() =>
        uniqueArray(searchInMessages.value.map((currentMessage) => currentMessage.sender))
      ),
      avaliableRecipients: computed(() =>
        uniqueArray(searchInMessages.value.map((currentMessage) => currentMessage.recipients?.join(", ")))
      ),
    };
  },
});
